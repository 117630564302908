import * as React from 'react';

import {useAppSelector} from '../../../hooks';
import {getPlace} from '../../../utils/rating';
import {FacultyColumn} from './facultyСolumn';
import {FacultyInfo} from "./facultyInfo";

function Rating(): JSX.Element {
    const faculties = useAppSelector((state) => state.faculties);
    const N = faculties.find((f) => f.character === 'N');
    const C = faculties.find((f) => f.character === 'C');
    const Z = faculties.find((f) => f.character === 'Z');
    const R = faculties.find((f) => f.character === 'R');

    return (
        <>
            <hr className="rating__start" />
            <section className="rating__wrapper">
                <FacultyColumn src={`/images/N.svg`} place={getPlace('N', faculties)} colorVar={'accent-color_third'}
                               name={'Нэша'}/>
                <FacultyColumn src={`/images/C.svg`} place={getPlace('C', faculties)} colorVar={'accent-color_main'}
                               name={'Кантора'}/>
                <FacultyColumn src={`/images/Z.svg`} place={getPlace('Z', faculties)} colorVar={'accent-color_first'}
                               name={'Цермело'}/>
                <FacultyColumn src={`/images/R.svg`} place={getPlace('R', faculties)} colorVar={'accent-color_second'}
                               name={'Римана'}/>
            </section>
            <hr className="rating__end" />
            <section className="faculty-info__container">
                <FacultyInfo name={N?.name ?? ''} tasksCount={N?.tasksCount ?? 0} score={N?.score ?? 0}/>
                <FacultyInfo name={C?.name ?? ''} tasksCount={C?.tasksCount ?? 0} score={C?.score ?? 0}/>
                <FacultyInfo name={Z?.name ?? ''} tasksCount={Z?.tasksCount ?? 0} score={Z?.score ?? 0}/>
                <FacultyInfo name={R?.name ?? ''} tasksCount={R?.tasksCount ?? 0} score={R?.score ?? 0}/>
            </section>
        </>
    );
}

export default Rating;